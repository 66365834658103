.Header_section {
  background-color: #ffff;
  position: sticky;
  top: 0;
  z-index: 9999;
}
.no-scroll{
  overflow: hidden;
}
.show_nav {
  position: fixed;
  top: -150px;
  width: 100%;
  transition: top 0.3s;
  z-index: 99999;
}
.troo_header {
  max-width: 1575px;
  margin: 0 auto;
  margin-bottom: -49px;
  z-index: 999999;
}

.troo_header_address_section {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
}
.troo_address {
  display: flex;
  column-gap: 33px;
}
.address_icons {
  color: #673ab7;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.address_default h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #292929;
  margin-top: 0;
  margin-bottom: 0;
}
.logoImg {
  width: 100%;
}
.troo_address_location,
.troo_address_mail,
.troo_icons {
  display: flex;
  column-gap: 6px;
  align-items: center;
}

.troo_icons {
  padding: 11px 0 11px 8%;
}
.troo_address_header {
  display: flex;
  align-items: center;
  border-left: 1px solid #673ab730;
  border-right: 1px solid #673ab730;
  padding: 0 23px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #191825;
}
.troo_address_header_bold {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #512da8;
}
h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #292929;
}
h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #191825;
}
.troo_icon_text {
  align-items: center;
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #191825;
  margin-bottom: 0rem;
}
.troo_nav_bar {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: 0.5s;
  border-radius: 5px;
  justify-content: space-between;
  border-top: 2px solid #673ab730;
  background: #ffff;
  box-shadow: 0px 6px 20px rgba(30, 30, 30, 0.1);
}
.troo_nav_bar ul {
  display: flex;
  margin-bottom: 0;
}

.navmanu_contain ul {
  margin-top: 0;
  margin-bottom: 0rem;
  padding-left: 0rem;
}
ul {
  list-style: none;
  gap: 50px;
  margin-bottom: 0;
}
.nav-manu ul {
  display: flex;
}
.nav-manu li a {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #191825 !important;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

.nav-manu .active {
  padding-bottom: 12px;
  border-bottom: 3px solid #512da8;
  color: #673ab7;
}
.nav-manu li a::before {
  contain: "";
  position: absolute;
  top: 0;
  width: 30px;
  height: 2px;
}
.btn_bk_apontmt{
  padding: 13px 13px !important;
}
.webLogo {
  background: linear-gradient(90deg, #673ab7 0%, #512da8 100%);
  padding: 21px 22px;
}

.numbertext p {
  margin-bottom: 0;
}
.contact_class a{
  color: #191825;
  text-decoration: none;
  cursor: pointer;
}
.btn_text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #191825;
  padding: 14px 18px;
  margin-bottom: 0;
}
.button:hover .btn_text {
  color: #ffff;
  transition: 1s all;
}
.button {
  color: #fff;
  border: 1px solid #673ab7;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  border-radius: 5px;
  background: transparent;
}
.button:focus,
.button:hover {
  color: #673ab7;
  background-color: transparent;
  border-color: #673ab7;
}
.button:before {
  content: "";
  background: linear-gradient(to right bottom, #673ab7 50%, transparent 50%);
  width: 40px;
  height: 40px;
  position: absolute;
  left: -7px;
  top: -7px;
  z-index: -1;
  transition: 0.5s all;
}
.button:focus:before,
.button:hover:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right bottom, #673ab7 50%, #673ab7 100%);
}

.imgphone {
  background: linear-gradient(90deg, #673ab7 0%, #512da8 100%);
  height: 108px;
  display: flex;
  align-items: center;
  padding: 14px;
  cursor: pointer;
}
.numbertext {
  background: #f6f5fb;
  border-radius: 0px 5px 5px 0px;
  height: 108px;
  padding: 20px;
}
.contact_class {
  display: flex;
  align-items: center;
}
.contactNumber {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #191825;
}
.humburgerLogo {
  display: none;
}

.hamburger_btn_manu {
  width: 25%;
  background-color: rgb(14 13 13 / 99%);
  position: fixed;
  top: 84px;
  z-index: 1;
  height: 100%;
  overflow: hidden;
  right: 0;
}
.drawer_manu {
  display: none;
}

.hamburger_btn_manu {
  transform: translateX(100%);
}
.drawer_open {
  transform: translateX(0);
  transition: 0.7s;
}
.drawer_close {
  transition: 0.7s;
}

.hamburger_btn_manu li a {
  font-size: 20px;
  color: #ffffff;
  text-decoration: none;
}
.drawer_manu ul {
  padding-left: 0;
  display: block;
}

.contact_drawer p {
  margin-bottom: 0;
}
a.contact_drawer{
  text-decoration: none;
  color: inherit;
}
.drawer_manu ul .active {
  color: #673ab7;
}
@media only screen and (max-width: 1500px) {
  .webLogo {
    padding: 12px 12px;
  }
  .numbertext {
    height: 90px;
  }
  .imgphone {
    height: 90px;
  }
  ul {
    gap: 30px;
  }
}
@media only screen and (max-width: 1350px) {
  .nav_bar_btn {
    display: none;
  }
}
@media only screen and (max-width: 1180px) {
  .troo_header_address_section {
    display: none;
  }
  .contact_class {
    display: none;
  }

  .logoImg {
    width: 90%;
  }
  .humburgerLogo {
    display: block;
    height: 77px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: white;
    width: 80px;
  }
  .nav-manu {
    top: 0;
    left: -100%;
    z-index: -3;
    transition: 0.3s;
    display: none;
    margin-right: 20px;
  }

  .troo_nav_bar {
    background-color: #512da8;
  }
  .drawer_manu {
    display: block;
    text-align: center;
  }
}
@media only screen and (max-width: 950px) {
  .logoImg {
    width: 80%;
  }
  .hamburger_btn_manu {
    top: 78px;
  }
}
@media only screen and (max-width: 767px) {
  .button {
    margin-bottom: 20px;
  }
  .drawer_manu {
    display: block;
  }
  .hamburger_btn_manu {
    width: 100%;
  }
}
