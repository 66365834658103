.troo_banner_section {
  background-image: url(../../Images//BannerMain.png);
  width: 100%;
  position: relative;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.troo_banner_container_fluid {
  display: flex;
  justify-content: space-between;
}

.troo_banner_text_container {
  padding: 162px 0 322px 16%;
  width: 53%;
}

.Banner_btn_group {
  display: flex;
  column-gap: 30px;
}
.troo_banner_image {
  background-image: url(../../Images//bannerImg.png);
  width: 100%;
  height: 100%;
  background-position: right;
  background-repeat: no-repeat;
}
.troo_banner_text_container h5 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}
.troo_banner_text_container h1 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 87px;
  color: #ffffff;
}
.banner_subtext {
  background: linear-gradient(
    90deg,
    #673ab7 1.72%,
    rgba(103, 58, 183, 0) 96.34%
  );
  border-radius: 5px;
  width: 232px;
  padding: 7px 10px;
  color: #fff;
  margin-bottom: 35px;
}
h1 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 87px;
  color: #ffffff;
}
.banner_main_heading {
  margin-bottom: 45px;
}
p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}
.banner_text {
  margin-bottom: 50px;
  color: #ffff;
  border-left: 2px solid #fff;
  padding-left: 24px;
  max-width: 616px;
}

.banner_btn_text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #ffff;
  margin-bottom: 0rem;
}
.bannerBtn:hover .banner_btn_text {
  color: #ffffff;
  transition: 1s all;
  font-weight: 400;
}
.bannerBtn {
  color: #fff;
  font-weight: 700;
  padding: 13px 10px;
  letter-spacing: 1px;
  border-radius: 0;
  border: 1px solid #673ab7;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  border-radius: 5px;
  background: transparent;
}
.bannerBtn:focus,
.bannerBtn:hover {
  color: #673ab7;
  background-color: transparent;
  border-color: #673ab7;
}
.bannerBtn:before {
  content: "";
  background: linear-gradient(to right bottom, #673ab7 50%, transparent 50%);
  width: 40px;
  height: 40px;
  position: absolute;
  left: -7px;
  top: -7px;
  z-index: -1;
  transition: 0.5s all;
}
.bannerBtn:focus:before,
.bannerBtn:hover:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right bottom, #673ab7 50%, #673ab7 100%);
}

/* nortmal btn */
.normal_btn {
  background: linear-gradient(90deg, #673ab7 0%, #512da8 100%);
  box-shadow: 0px 6px 20px rgba(30, 30, 30, 0.1);
  border-radius: 5px;
  padding: 13px 20px;
  border: none;
}
.normal_btn:hover{
  background: #0059ff;
  transition: .4s ease-in;
}
/* .normal_btn {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #ffffff;
  background: #0059ff;
  padding: 13px 20px;
  border-radius: 5px;
  border: none;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}
.normal_btn:hover {
  color: #000;
}
.normal_btn:before,
.normal_btn:after {
  content: "";
  background: #673ab7;
  height: 100%;
  width: 55%;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out;
}
.normal_btn:after {
  left: auto;
  right: 0;
}
.normal_btn:hover:before,
.normal_btn:hover:after {
  width: 0;
} */

.troo_banner_img {
  width: 50%;
  display: flex;
  justify-content: end;
}
.bannerImg {
  width: 94%;
  height: 90%;
}

@media only screen and (max-width: 1860px) {
  .troo_banner_text_container {
    padding: 100px 0 140px 7%;
    width: 100%;
  }
  .troo_banner_img {
    width: 100%;
  }
  .bannerImg {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 1300px) {
  .troo_banner_text_container {
    padding: 100px 0 69px 10%;
    width: 100%;
  }
}
@media only screen and (max-width: 1180px) {
  .troo_banner_text_container {
    padding: 80px 0 25px 9%;
    width: 50%;
  }
  .troo_banner_img {
    width: 50%;
  }
  .troo_banner_text_container h1 {
    font-size: 48px;
    line-height: 67px;
  }
}
@media only screen and (max-width: 1024px){
  .troo_banner_text_container {
    padding: 120px 0 75px 3%;
    width: 50%;
}
.bannerImg {
  height: 85%;
}
}
@media only screen and (max-width: 768px) {
  .troo_banner_text_container {
    padding: 100px 0 50px 4%;
    width: 100%;
}
.troo_banner_text_container h1 {
  font-size: 48px;
  line-height: 70px;
}
  .troo_banner_img {
    display: none;
  }
  .normal_btn {
    margin-bottom: 30px;
  }
 
  
  .banner_text {
    margin-bottom: 24px;
    padding-left: 15px;
  }
  .troo_banner_text_container h5 {
    font-size: 13px;
    line-height: 16px;
  }
  .banner_subtext {
    width: 178px;
  }
  .banner_btn_text {
    font-size: 10px;
    line-height: 10px;
  }
  .bannerBtn:before {
    width: 30px;
    height: 30px;
    left: -5px;
    top: -5px;
  }
}
@media only screen and (max-width: 540px) {
  .troo_banner_text_container {
    padding: 75px 20px 0px 20px;
    width: 100%;
  }
  .troo_banner_text_container h1 {
    font-size: 26px;
    line-height: 36px;
  }
  .banner_main_heading {
    margin-bottom: 15px;
  }
  p {
    font-size: 12px;
    line-height: 16px;
  }
  .banner_subtext {
    margin-bottom: 10px;
  }
 
  .bannerImg {
    display: none;
  }
  .troo_banner_container_fluid {
    display: block;
  }
}
