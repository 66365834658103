.portfoliobg {
  position: relative;
  z-index: 1;
}
.portfoliobg:before {
  content: "";
  background-image: url(../../Images/portfolioBG.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.portfoliobg:after {
  content: "";
  background-color: #673ab7;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.portfolio_banner_container {
  max-width: 1620px;
  margin: 0 auto;
  padding: 55px 0 345px 0;
}
.portfolio_testimonial_BG {
  position: relative;
  z-index: 1;
}
.portfolio_testimonial_BG::before {
  content: "";
  background-image: url(../../Images/testimonialBG.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.portfolio_testimonial_BG::after {
  content: "";
  background-color: #512da810;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
}
.portfolio_carosal_container {
  max-width: 1620px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  margin-top: -310px;
  padding: 0 30px;
}
.portfolio_subtext {
  background: linear-gradient(
    89.43deg,
    rgba(255, 255, 255, 0.5) 2.11%,
    rgba(255, 255, 255, 0) 54.42%
  );
  border-radius: 5px;
  color: #ffff;
  padding: 10px 15px;
  width: 170px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 35px;
}
.portfolio_text_cont {
  padding: 10px 10px;
}
.portfolioImg {
  width: 100%;
  z-index: -1;
  position: relative;
}
.portfolio_text_cont {
  z-index: 1;
  position: absolute;
  bottom: 50px;
  width: 70%;
  padding: 20px 15px;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 5px;
  left: 50%;
  opacity: 0;
}
.block_circle {
  position: absolute;
  bottom: 95px;
  right: 25px;
  color: #ffff;
  border-radius: 50%;
  background-color: #673ab7;
  border: 2px solid #ffff;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 30px;
}
.portfolio_heading {
  display: flex;
  justify-content: space-between;
  padding: 0 0 50px 0;
}
.carousel-button-group {
  position: absolute;
  display: none;
}
.btn_groups {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.arrow_btn {
  border-radius: 50%;
  border: 1px solid #ffff;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 45px;
  color: #ffff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.portfolioHeading {
  color: #ffff;
}
.arrow_btn:hover {
  background-color: #ffff;
  color: black;
  transition: 0.8s all;
  cursor: pointer;
  color: #673AB7;
}

.portfolio_carosel_card:hover .portfolio_text_cont {
  opacity: 1;
  z-index: 3;
  transition: 0.8s all;
}
.portfolio_carosel_card::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #512da880;
  z-index: 1;
  opacity: 0;
  transition: 0.8s all;
  border-radius: 5px;
}
.portfolio_carosel_card {
  position: relative;
  border-radius: 5px;
}
.portfolio_carosel_card:hover::after {
  opacity: 1;
  transition: 0.8s all;
}
.portfolio_block_heading {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  background: linear-gradient(90deg, #673ab7 0%, #512da8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.skill_container {
  max-width: 1360px;
  background: #ffffff;
  box-shadow: 0px 6px 20px rgb(30 30 30 / 10%);
  border-radius: 5px;
  margin: 100px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}
.counts {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  text-align: center;
  text-transform: capitalize;
  color: #191825;
  margin-bottom: 0;
}
.transparent {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  text-align: center;
  text-transform: capitalize;
  color: #191825;
  opacity: 0.05;
  position: absolute;
  margin-bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.skill_container .cards {
  padding: 20px 0px;
  position: relative;
}

.contents::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 75px;
  right: 0;
  z-index: 99;
  background: #191825;
  opacity: 0.2;
  background-color: rebeccapurple;
}
.contents {
  padding: 48px 0px;
  text-align: center;
  flex: 1;
  position: relative;
}
.skill_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  text-transform: capitalize;
  color: #191825;
}
.portfolio_blog_para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  text-transform: capitalize;
  color: #191825;
  margin-bottom: 0;
}
.portfolio_carousel .react-multi-carousel-track {
  column-gap: 25px;
}
@media only screen and (max-width: 1420px){
  .skill_container {
    margin: 100px 30px;
  }
}

@media only screen and (max-width: 1300px) {
  .portfolio_banner_container {
    padding: 80px 20px 240px 20px;
  }
  
  .skill_container {
    margin: 70px 30px;
  }
  .portfolio_carosal_container{
    padding: 50px 30px;
  }
}
@media only screen and (max-width: 1180px) {
  .counts {
    font-size: 45px;
  }
  .transparent {
    font-size: 80px;
  }
  .contents {
    padding: 25px 0px;
  }
}
@media only screen and (max-width: 768px) {
  .skill_container {
    margin: 40px 30px;
  }
  .contents {
    padding: 10px 0px;
  }
  .transparent {
    font-size: 50px;
  }
  .counts {
    font-size: 30px;
  }
  .skill_text {
    font-size: 12px;
    line-height: 12px;
  }
  .portfolio_carousel .react-multi-carousel-track {
    column-gap: 0px;
  }
  /* .portfolio_carousel {
    padding: 0 20px;
  } */
}
@media only screen and (max-width:540px){
  .skill_container{
    display: block;
  }
  .skill_text {
    font-size: 17px;
    line-height: 20px;
}
.contents::before {
 
  content: "";
  position: absolute;
  width: 100px;
  height: 1px !important;
  bottom: 0;
  right: 50%;
  z-index: 99;
  background: #191825;
  opacity: 0.2;
  background-color: rebeccapurple;
  transform: translateX(50%);
}
}
@media only screen and (max-width:415px) {
  .portfolio_banner_container {
    padding: 20px 20px 150px 20px;
}
  .portfolio_block_heading {
    font-size: 17px;
    line-height: 20px;
  }
  .block_circle {
    bottom: 82px;
    width: 30px;
    height: 30px;
    line-height: 20px;
  }
  .skill_container .cards {
    padding: 10px 0px;
  }
  .transparent {
    font-size: 40px;
  }
  .counts {
    font-size: 25px;
  }
  .contents::before {
    height: 40px;
  }
  .portfolio_text_cont {
    bottom: 25px;
    width: 80%;
    padding: 25px 10px;
  }
  .portfolio_blog_para {
    font-size: 12px;
    line-height: 16px;
  }
  .skill_container{
    margin: 0 20px 20px 20px;
  }
  .portfolio_banner_container {
    padding: 20px 20px 170px 20px;
  }
  .portfolio_carosal_container {
    margin-top: -200px;
    padding: 50px 20px;
  }
  .arrow_btn {
    display: none;
  }
}
