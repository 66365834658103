.blogs_container {
  max-width: 1360px;
  margin: 0 auto;
  padding: 100px 30px;
}
.blogs_header {
  margin-bottom: 37px;
}
.blogs_cards_container {
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
}
.blogs_card {
  padding: 30px 25px;
  background: #ffffff;
  box-shadow: 0px 6px 20px rgb(30 30 30 / 10%);
  border-radius: 5px;
  position: relative;
  width: 32.33%;
}
.blogs_card:hover {
  opacity: 0.9;
  box-shadow: 0px 6px 20px rgba(30, 30, 30, 0.1);
  border-radius: 5px;
}
.blog_bg {
  background-image: url(../../Images/blog_card_bg.png);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-attachment: scroll;
  background-size: cover;
  background-position: top;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  opacity: 1;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}
.blog_bg:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #512da8;
  opacity: 0.9;
  content: "";
  border-radius: 7px;
}
.blogs_card:hover .blog_bg {
  transform: scaleY(1);
}

.blogs_card_header {
  display: flex;
  column-gap: 15px;
  justify-content: space-between;
}
.blogs_card_title {
  padding: 30px 0 20px 0;
}
.blogs_header h2 {
  margin-bottom: 0;
}
.blogs_card_header p {
  margin-bottom: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  border: 1px solid;
  border-radius: 5px;
  padding: 4px 10px;
  background: #191825;
  display: flex;
    align-items: center;
    justify-content: center;
}
.blogs_card:hover .blogs_card_header p {
  background-color: #ffffff;
  color: #191825;
}
.blogs_card_title h3 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  color: #191825;
  margin-bottom: 0;
}
.blogs_card:hover .blogs_card_title h3 {
  color: #ffffff;
}
.blogs_card_para p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #191825;
  margin-bottom: 28px;
}
.blogs_card:hover .blogs_card_para p {
  color: #ffffff;
}
.continue_reading p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #191825;
  margin-bottom: 0;
}
.blogs_card:hover .continue_reading p {
  color: #ffffff;
}
.continue_reading {
  text-decoration: none;
}

@media only screen and (max-width: 1300px) {
  .blogs_container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 1180px) {
  .blogs_card_title h3 {
    font-size: 20px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 923px){
  .blogs_cards_container{
    column-gap: 10px;
  }
  .blogs_card {
    padding: 30px 15px;
  }
}
@media only screen and (max-width: 768px) {
  .blogs_cards_container {
    flex-direction: column;
    row-gap: 30px;
  }
  .blogs_card_header {
    justify-content: space-between;
  }
  .blogs_card{
    width: 100%;
  } 
}

@media only screen and (max-width: 415px) {
  .blogs_container {
    padding: 50px 20px;
  }
  .blogs_card {
    padding: 15px;
  }
  .blogs_card_title h3 {
    font-size: 17px;
    line-height: 20px;
  }
  .blogs_card_title {
    padding: 15px 0;
  }
  .blogs_card_para p {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .blogs_card_header p {
    font-size: 12px;
    line-height: 20px;
  }
}
