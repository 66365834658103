.feture_text_container {
  max-width: 1360px;
  margin: 0 auto;
  padding: 50px 30px 0px 30px;
}
.feture_heading_cont {
  display: flex;
  justify-content: space-between;
}
.feture_heading_para::before {
  content: "";
  width: 142px;
  height: 142px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 15%;
  transform: translateY(-50%);
  z-index: -11;
  background: linear-gradient(90deg, #673ab7 0%, #512da8 100%);
  opacity: 0.05;
}
.feture_heading_para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  position: relative;
  color: #191825;
  z-index: 999;
  padding-left: 40px;
  margin-bottom: 0rem;
}
.feture_heading_right {
  padding-top: 15px;
}
.feture_card_section {
  background-image: url(../../Images//feture_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

.feture_cards {
  max-width: 1360px;
  margin: 0 auto;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 35px 30px 100px 30px;
}
.fetureCard {
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  text-align: center;
  padding: 50px 15px 15px 15px;
  opacity: 0.9;
  box-shadow: 0px 6px 20px rgba(30, 30, 30, 0.1);
}
.fetureCard:hover {
  opacity: 0.9;
  box-shadow: 0px 6px 20px rgba(30, 30, 30, 0.1);
  border-radius: 5px;
}
.fetureCard:hover .feture_card_para {
  color: #ffff;
  transition: 2s all;
}
.fetureCard:hover .feture_card_header {
  color: #ffffff;
  transition: 2s all;
}
.fetureCard:hover .feture_card_img {
  border: 5px solid #ffffff30;
}
.feture_card_img {
  padding: 10px;
  border: 5px solid #ffffff;
  border-radius: 50%;
  background: #673ab7;
}
h3 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
}
.feture_card_header {
  padding: 20px 0 0 0;
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #191825;
}
.feture_card_para {
  padding: 15px 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
}
.layer_outer {
  background-image: url(../../Images/feture_card_bg.png);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-attachment: scroll;
  background-size: cover;
  background-position: center;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  opacity: 1;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}
.layer_outer:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #512da8;
  opacity: 0.9;
  content: "";
  border-radius: 7px;
}
.fetureCard:hover .layer_outer {
  transform: scaleY(1);
}
.feture_ri8_arrow{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #512da8;
  box-shadow: 0px 6px 20px rgba(30, 30, 30, 0.1);
border-radius: 5px;
background-color: #ffffff;
margin: 25px auto;
}
@media only screen and (max-width: 1300px) {
  .feture_section {
    padding: 0 30px;
  }
}
@media only screen and (max-width: 1180px) {
  .feture_heading_para::before {
    content: "";
    width: 90px;
    height: 90px;
  }

}
@media only screen and (max-width: 768px) {
  .feture_heading_cont {
    display: block;
  }
  .feture_heading_para {
    margin-top: 30px;
  }
  .feture_cards{
    padding: 35px 0 50px 0;
  }
}
@media only screen and (max-width: 540px) {
  .feture_text_container {
    padding: 10px 0 0px 0;
  }
  .feture_heading_para {
    margin-top: 15px;
  }
  .feture_heading_para {
    font-size: 12px;
    line-height: 19px;
    padding-left: 23px;
  }
  .feture_heading_para::before {
    content: "";
    width: 50px;
    height: 50px;
  }
  .feture_card_img {
    width: 75px;
  }
  .feture_card_header {
    padding: 25px 0 0 0;
    font-size: 19px;
    line-height: 15px;
  }
  .feture_card_para {
    font-size: 12px;
    line-height: 19px;
  }
  .feture_cards {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 35px 0;
}
}

@media only screen and (max-width: 415px) {
  .feture_section {
    padding: 0 20px;
  }
}

/* @media (min-width: 600px) {
    .feture_cards { grid-template-columns: repeat(2, 1fr); }
  }
  @media (min-width: 900px) {
    .feture_cards { grid-template-columns: repeat(3, 1fr); }
  }
  @media (min-width: 1300px) {
    .feture_cards { grid-template-columns: repeat(4, 1fr); }
  } */
