.troo_aboutcontainer {
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 117px 30px 50px 30px;
  column-gap: 60px;
}
.troo_aboutcont_right,
.troo_aboutcont_left {
  width: 100%;
}
.aboutimg {
  width: 97.9%;
}
.subheading {
  background: linear-gradient(
    89.43deg,
    rgba(103, 58, 183, 0.5) 2.11%,
    rgba(103, 58, 183, 0) 54.42%
  );
  border-radius: 5px;
  max-width: 220px;
  padding: 10px 20px;
  margin-bottom: 35px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #191825;
}

.section_header {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 58px;
  color: #191825;
  margin-bottom: 0;
}
.about_para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #191825;
  padding: 22px 0px 22px 24px;
  margin-bottom: 0;
}

.left_line {
  position: relative;
}
.left_line::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 80px;
  left: 0;
  z-index: 99;
  background: #191825;
}
.about_cont_devider {
  display: flex;
  column-gap: 20px;
}
.about_subheading_line {
  position: relative;
  margin-left: 10px;
}
.about_subheading_line::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 2px;
  background: linear-gradient(90deg, #673AB7 0%, #512DA8 100%);
}
.about_cont_devider h4 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #191825;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
h4 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #191825;
}
.about_cont_devider p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #191825;
  padding: 14px 0 0 0;
  margin-bottom: 0;
}
.about_heighlight_cont {
  padding: 19px 0
}
.about_heighlight {
  display: flex;
  column-gap: 10px;
  padding-bottom: 19px;
  align-items: flex-start;
}
.about_heighlight p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #232323;
  margin-bottom: 0;
}
.width7 {
  width: 22px;
}

@media only screen and (max-width: 1300px) {
  .troo_aboutcontainer {
    padding: 70px 30px 50px 30px;
    column-gap: 55px;
  }
}
@media only screen and (max-width: 950px){
  .about_subheading_line::after{
    display: none;
  }
}
@media only screen and (max-width: 1180px) {
  .troo_aboutcontainer {
    padding: 50px 30px 50px 30px;
    column-gap: 20px;
  }
  .section_header {
    font-size: 32px;
    line-height: 45px;
  }
  .about_para {
    font-size: 16px;
    line-height: 25px;
    padding: 8px 18px;
  }
  .about_heighlight {
    padding: 5px 0;
  }
  .subheading {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .troo_aboutcontainer {
    flex-direction: column;
    padding: 50px 30px 10px 30px;
    row-gap: 30px;
  }
  .aboutimg {
    width: 100%;
  }
  .about_cont_devider{
    padding-top: 20px;
  }
}
@media only screen and (max-width: 415px) {
  .troo_aboutcontainer{
    padding: 50px 20px 0 20px;
  }
  .section_header {
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .about_cont_block{
    margin: 15px 0;
  }
  .about_para {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 10px;
  }
  .about_cont_devider h4 {
    font-size: 15px;
    line-height: 16px;
    margin-bottom: 0;
  }
  .about_cont_devider p {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 0 0 0;
  }
  .about_heighlight_cont {
    padding: 7px 0 20px;
  }
  .width7 {
    width: 20px;
  }
  .about_heighlight p {
    font-size: 12px;
    line-height: 24px;
  }
  .left_line::before {
    height: 55px;
  }
  .about_cont_devider {
    flex-direction: column;
  }
}
