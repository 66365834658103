.contact_section {
  position: relative;
  background-image: url(../../Images//contact_bG.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  margin-top: -160px;
}
.contact_section::after {
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(90deg, #673ab7 0%, #512da8 100%);
  opacity: 0.05;
  z-index: -1;
}
.contact_container {
  max-width: 1360px;
  margin: 0 auto;
  padding: 260px 30px 100px 30px;
}
.contact_heading {
  display: flex;
  justify-content: space-between;
  column-gap: 85px;
}
.contact_right {
  display: flex;
  justify-content: end;
  column-gap: 11px;
  width: 50%;
  align-items: center;
}
.contact_left {
  width: 50%;
}
.contact_btn_inner {
  display: flex;
  column-gap: 16.77px;
  padding: 15px 10px;
}
.phone_icon {
  display: flex;
  font-size: 24px;
  align-items: center;
}
.phone_text_cont {
  text-align: left;
}
.phone_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
.phone_num {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #ffffff;
}
.find_contact {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}
.find_contact_highlight {
  color: #673ab7;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  font-family: "Jost";
}
.find_contact p {
  margin-top: 0;
  margin-bottom: 0;
}
.phone_text_cont p {
  margin-top: 0;
  margin-bottom: 0;
}
.contact_accordian_container {
  display: flex;
  justify-content: space-between;
  padding: 60px 0 30px 0;
  column-gap: 30px;
}
.accordion_contact_left,
.accordion_ncontact_right {
  width: 100%;
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 0 !important;
  --bs-accordion-btn-padding-y: 0;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon-width: 1rem !important;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 0;
  --bs-accordion-active-color: #512da8 !important;
  --bs-accordion-active-bg: white !important;
  max-width: 100%;
}
.accordion-button:not(.collapsed)::after {
  background-image: url(../../Images/chevron_up.svg) !important;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: url(../../Images/chevron_down.svg) !important;
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transform: rotate(0deg) !important;
  margin-top: 7px;
}
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: none;
  margin-bottom: 20px;
  padding: 2px 18px;
  box-shadow: 0px -6px 20px rgb(0 0 0 / 5%), 0px 8px 20px rgb(0 0 0 / 5%);
}
.accordion-button:focus {
  box-shadow: unset;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: none;
  border-bottom-left-radius: none;
}
.accordion-button {
  position: relative;
  display: block;
  align-items: flex-start !important;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 20px !important;
  line-height: 29px !important;
  color: #191825;
  column-gap: 11px;
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: none;
  box-shadow: none !important;
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #191825;
  border-top: 1px solid #16161610;
  padding: 20px 0 10px 0;
}
.questionmark {
  height: 29px;
  width: 29px;
  background-color: #512da810;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.accordian_btn {
  text-align: center;
}
.cont_btn {
  padding: 14px 23px;
}

@media only screen and (max-width: 1300px) {
  .contact_container {
    padding: 227px 30px 50px 30px;
  }
}
@media only screen and (max-width: 1180px) {
  .accordion-button {
    font-size: 18px !important;
    align-items: flex-start !important;
  }
  .accordion {
    --bs-accordion-btn-padding-y: 10px !important ;
  }
}
@media only screen and (max-width: 768px) {
  .contact_heading {
    display: block;
  }
  .contact_left,
  .contact_right {
    width: 100%;
  }
  .contact_right {
    justify-content: space-between;
  }
  .contact_accordian_container {
    flex-direction: column;
    row-gap: 30px;
  }
  .accordion-button {
    align-items: flex-start !important;
  }
}
@media only screen and (max-width: 600px) {
  .contact_right {
    justify-content: space-between;
    display: grid;
    row-gap: 20px;
    margin-top: 20px;
  }
  .contact_accordian_container {
    padding: 20px 0 30px 0;
}
}

@media only screen and (max-width: 415px) {
  .contact_btn_inner {
    padding: 0px 0px;
  }
  .accordion-button {
    font-size: 13px !important;
    padding: 5px 0 !important;
    line-height: 20px !important;
  }
  .accordion-body {
    padding: 5px 0 !important;
    font-size: 12px;
    line-height: 20px;
  }
  .accordion-item {
    padding: 2px 7px;
  }

  .contact_accordian_container {
    padding: 5px 0;
  }
  .contact_container {
    padding: 210px 20px 20px 20px;
  }
  .questionmark {
    height: 25px;
    width: 25px;
    font-size: 12px;
  }
}
