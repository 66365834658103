.troo_service_section {
  background-image: url(../../Images//servicebg.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.troo_service_container {
  max-width: 1360px;
  margin: 0 auto;
  padding: 100px 30px;
}

.service_sub_heading {
  margin: 0 auto;
  margin-bottom: 35px;
}
.service_main_heading {
  text-align: center;
  padding: 0 340px;
}
.service_cards {
  max-width: 1300px;
  margin: 0 auto;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  padding: 45px 0 0 0;
}
.servicecard {
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  text-align: center;
}
.serviceImg {
  width: 100%;
  position: relative;
  z-index: 1;
}
.serviceBlock {
  z-index: 1;
  position: absolute;
  bottom: 20px;
  transform: translateX(17%);
  width: 73%;
  padding: 16px 15px;
  background-color: #ffffff;
  border-radius: 5px;
}
.service_hover_block {
  padding: 20px;
  background: linear-gradient(90deg, #673ab7 0%, #512da8 100%);
  border-radius: 5px;
  color: #ffff;
  width: 80%;
  transform: translateX(13%);
  z-index: 2;
  position: absolute;
  bottom: 20px;
  opacity: 0;
  border-bottom: 1px solid #fff;
}
.service_hover_block h3 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}
.serviceBlock h3 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #191825;
}
.service_hover_block p {
  margin: 20px 0;
}
.service_hover_block h5 {
  color: #ffff;
  text-decoration: underline;
}
.servicecard:hover .service_hover_block {
  opacity: 1;
  transition: 0.8s all;
}
.servicecard:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #512da880;
  z-index: 1;
  opacity: 0;
  transition: 0.8s all;
  border-radius: 5px;
}
.servicecard:hover::after {
  opacity: 1;
  transition: 0.8s all;
}
.servicecard:hover .serviceBlock {
  opacity: 0;
}

.service_buttom_text h4::before {
  content: "";
  position: absolute;
  background-image: url(../../Images/Polygon.png);
  background-repeat: no-repeat;
  width: 95px;
  height: 95px;
  top: -12px;
  right: -40px;
}
.service_buttom_text {
  display: flex;
  justify-content: center;
  padding: 43px 0 0 0;
  position: relative;
}
.service_buttom_text p {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #191825;
  margin-bottom: 0;
}
.service_buttom_text a {
  color: #673ab7;
}
.service_buttom_text h4 {
  color: #673ab7;
  margin-left: 10px;
  position: relative;
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
}
.view_dtls {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #ffffff;
}

@media only screen and (max-width: 1300px) {
  .troo_service_container {
    padding: 50px 30px;
  }
  .service_main_heading {
    display: flex;
    margin-bottom: 8px;
    font-size: 40px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 1140px) {
  .service_main_heading {
    font-size: 32px;
    line-height: 45px;
  }
  .service_main_heading {
    padding: 0 250px;
  }
}
@media only screen and (max-width: 860px) {
  .service_main_heading {
    padding: 0 160px;
  }
}
@media only screen and (max-width: 768px) {
  .troo_service_section {
    background-image: none;
  }
}
@media only screen and (max-width: 680px) {
  .service_main_heading {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 415px) {
  .service_main_heading {
    font-size: 22px;
    line-height: 22px;
  }
  .service_cards {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 30px 0 0 0;
  }
  .service_hover_block h3 {
    font-size: 19px;
    line-height: 25px;
  }
  .view_dtls {
    font-size: 14px;
  }
  .troo_service_container {
    padding: 50px 20px 20px 20px;
  }
  .service_buttom_text {
    display: flex;
    justify-content: center;
    padding: 30px 0 10px 0;
  }
  .serviceBlock h3 {
    font-size: 19px;
    line-height: 15px;
  }
  .serviceBlock {
    transform: translateX(10%);
    width: 85%;
    padding: 20px 10px;
  }
  .service_buttom_text p {
    font-size: 13px;
    line-height: 29px;
  }
  .service_buttom_text h4 {
    font-size: 15px;
  }
}
/* @media (min-width: 600px) {
    .service_cards { grid-template-columns: repeat(2, 1fr); }
  }
  @media (min-width: 900px) {
    .service_cards { grid-template-columns: repeat(3, 1fr); }
  }
  @media (min-width: 1300){
    .service_cards { grid-template-columns: repeat(3, 1fr); }
  } */
