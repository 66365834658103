.footer_section {
  background-image: url(../../Images/footer_bg.png);
  background-position: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  position: relative;
}
.footer_section::after {
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(90deg, #673ab7 0%, #512da8 100%);
  opacity: 0.05;
  z-index: -1;
}
.footer_container {
  max-width: 1360px;
  padding: 0 30px;
  margin: 0 auto;
}
.f_heading {
  text-align: center;
  color: #191825;
}
.footer_header_para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #0a142f;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 0;
}
.footer_header {
  padding: 82px 0 57px 0;
  text-align: center;
}
.scarch {
  width: 620px;
  height: 50px;
  padding: 15px;
  border-radius: 5px;
  background-color: #512da800;
  border: 2px solid #512da820;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #777777;
}
form {
  margin-top: 40px;
}
.sub_btn {
  padding: 14px 17px 13px 17px;
}
.subscribe_btn_text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 0;
}
.scarch:focus-visible {
  outline: none;
}
.customHR {
  border: 0;
  border-top: 1px solid #191825;
  opacity: 0.1;
  margin: 0;
}
.footer_manu_container {
  display: flex;
  justify-content: space-between;
  padding: 50px 0 35px 0;
  column-gap: 50px;
}

.footer_logo_para p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #191825;
  margin-bottom: 0;
  padding: 25px 0;
}
.footer_log_img {
  width: 50%;
}
.contact_us_container {
  padding: 40px 35px;
  background: linear-gradient(90deg, #673ab7 0%, #512da8 100%);
  border-radius: 5px;
}
.contact_us_container h3 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  margin-bottom: 0;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.contact_us_container h3::after {
  content: "";
  width: 25px;
  height: 3px;
  position: absolute;
  z-index: 9;
  background: #ffffff;
  bottom: 0;
  left: 0;
}
.call_detaile {
  display: flex;
  column-gap: 15px;
}
.footer_logo {
  background: linear-gradient(90deg, #673ab7 0%, #512da8 100%);
  padding: 21px 22px;
  width: 60%;
}
.footer_address_icon {
  color: #ffffff;
  font-size: 25px;
  display: flex;
  align-items: center;
}
.footer_address_details p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 0;
}
.footer_address_details h4 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 0;
}
.manu_left_manu ul {
  margin-top: 32px;
  margin-bottom: 0;
  padding-left: 0;
}
.manu_right_manu ul {
  margin-top: 32px;
  margin-bottom: 0;
  padding-left: 0;
}
.manu_left_contact_us {
  padding: 7px;
  background: #ffffff;
  box-shadow: 0px 7px 29px rgb(100 100 111 / 20%);
  border-radius: 5px;
}
.manu_left_manu {
  width: 50%;
}
.footer_nav_manu h4 {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-transform: capitalize;
  color: #191825;
  position: relative;
  z-index: 1;
}
.footer_nav_manu::after {
  content: "";
  width: 25px;
  height: 3px;
  position: absolute;
  z-index: 9;
  background: linear-gradient(90deg, #673ab7 0%, #512da8 100%);
}
.footer_list li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #191825;
  margin-bottom: 15px;
}
.footer_social_icons {
  display: flex;
  column-gap: 5px;
}
.dashed_line {
  border: 1px dashed #512da8;
  opacity: 0.7;
}
.footer_term_condtion {
  display: flex;
  column-gap: 10px;
}
.footer_bottom {
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
}
.footer_bottom p {
  margin-bottom: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.204545px;
  color: #191825;
}
.footer_bottom_hr {
  border: 1px dashed #512da8;
  opacity: 0.7;
  margin: 1rem 0 0 0;
}
.footer_icon_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 0;
}
.footer_contacts {
  display: flex;
  flex-direction: column;
  row-gap: 27px;
}
.footer_card_left {
  width: 36%;
}
.footer_middle {
  width: 35%;
  display: flex;
  column-gap: 140px;
}
.footer_card_right {
  width: 28%;
}

.form_cont {
  position: relative;
  width: fit-content;
  margin: 35px auto 0;
}
.scarch_btn {
  position: absolute;
  right: 0;
}
.trootheme {
  font-weight: 700;
  color: #673ab7;
  text-decoration: none;
}

@media only screen and (max-width: 1300px) {
  .footer_container {
    padding: 0 30px;
  }
  .footer_header {
    padding: 40px 0 40px 0;
    text-align: center;
  }
}
@media only screen and (max-width: 1180px) {
  .footer_logo {
    width: 100%;
    padding: 10px 10px;
  }
  .footer_manu_container {
    column-gap: 50px;
  }
  .footer_middle {
    width: 33.33%;
    display: flex;
    column-gap: 60px;
  }
  .footer_card_left {
    width: 33.33%;
  }
  .footer_card_right {
    width: 33.33%;
  }
}
@media only screen and (max-width: 1024px) {
  .footer_middle {
    column-gap: 40px;
  }
  .footer_manu_container {
    column-gap: 30px;
  }
  .footer_logo {
    width: 215px;
  }
  .contact_us_container {
    padding: 20px 20px;
  }
  .footer_contacts {
    row-gap: 12px;
  }
  .contact_us_container h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .footer_address_details h4 {
    font-size: 18px;
    line-height: 23px;
  }
}
@media only screen and (max-width: 950px) {
  .footer_middle {
    column-gap: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .footer_manu_container {
    flex-direction: column;
    row-gap: 20px;
  }
  .footer_logo {
    width: 40%;
  }
  .footer_middle {
    column-gap: 30px;
    display: flex;
    justify-content: space-between;
  }
  .footer_middle {
    width: 100%;
    display: flex;
    column-gap: 60px;
  }
  .footer_card_left {
    width: 100%;
  }
  .footer_card_right {
    width: 100%;
  }
  .scarch_btn{
    position: relative;
    
    
  }
  .scarch{
    width: 100%;
    margin-bottom: 10px;
  }
  .form_cont{
    text-align: left;
  }
}

@media only screen and (max-width: 415px) {
  .footer_header p {
    font-size: 12px;
    line-height: 20px;
  }
  form {
    margin-top: 20px;
  }
  .scarch {
    min-width: 280px;
    height: 45px;
  }
  .scarch_btn {
    display: flex;
    text-decoration: none;
   
  }
  .footer_header {
    padding: 40px 0 0 0;
  }
  .footer_manu_container {
    padding: 25px 0 35px 0;
  }
  .footer_logo_para p {
    font-size: 12px;
    line-height: 20px;
    padding: 15px 0;
  }
  .footer_nav_manu h4 {
    font-size: 17px;
  }
  .footer_list li {
    font-size: 12px;
    line-height: 15px;
    padding: 10px 0 0 0;
  }
  .contact_us_container {
    padding: 20px;
  }
  .contact_us_container h3 {
    font-size: 19px;
    line-height: 25px;
  }
  .footer_address_details p {
    font-size: 12px;
    line-height: 20px;
  }
  .footer_address_details h4 {
    font-size: 16px;
    line-height: 25px;
  }
  .footer_bottom{
    flex-direction: column;
  }
  .footer_term_condtion {
    justify-content: space-between;
}
}
