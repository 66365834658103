.highlight_section {
  position: relative;
  z-index: 1;
}
.highlight_section::before {
  content: "";
  background-image: url(../../Images/compareBG.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.highlight_section::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background: linear-gradient(90deg, #673ab7 0%, #512da8 100%);
  opacity: 0.05;
}
.width100 {
  width: 100%;
}
.highlight_container {
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  padding: 100px 30px;
  column-gap: 46px;
}
.highlight_left {
  width: 50%;
}
.highlight_right {
  width: 50%;
}
.highlight_text_lists {
  display: flex;
  column-gap: 20px;
  margin-bottom: 35px;
  position: relative;
  z-index: 99;
}
.highlight_num {
  border-radius: 50%;
  height: 50px;
  background: #d9d9d9;
  box-shadow: 0px 6px 20px rgb(30 30 30 / 10%);
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.highlight_heading {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #191825;
  padding-bottom: 15px;
  padding-top: 5px;
}
.highlight_para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 0;
}
.highlight_text_lists:hover .highlight_heading {
  color: #673ab7;
}
.highlight_text_lists:hover .highlight_num {
  color: #512da8;
  background-color: #512da820;

}
.highlight_left p {
  padding: 20px 0 25px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 0;
}

@media only screen and (max-width: 1300px) {
  .highlight_container {
    padding: 100px 30px;
  }
}
@media only screen and (max-width: 1180px) {
  .highlight_container {
    padding: 50px 30px;
  }
  .highlight_heading {
    font-size: 22px;
    line-height: 22px;
  }
  .highlight_num {
    height: 40px;
    font-size: 22px;
    line-height: 22px;
    width: 40px;
  }
  .highlight_section::before {
    background-image: none;
  }
}
@media only screen and (max-width: 768px) {
  .highlight_container {
    flex-direction: column;
    row-gap: 30px;
  }
  .highlight_left,
  .highlight_right {
    width: 100%;
  }
  .highlight_section::before {
    display: none;
  }
}
@media only screen and (max-width: 415px) {
  .highlight_container {
    padding: 50px 20px 0 20px;
}
  .highlight_heading {
    font-size: 19px;
    line-height: 22px;
  }
  .highlight_para {
    font-size: 12px;
    line-height: 20px;
  }
  .highlight_num {
    height: 35px;
    font-size: 19px;
    line-height: 19px;
    width: 35px;
  }
}
