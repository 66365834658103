.troo_video_section {
  background-image: url(../../Images/videoSectionImg.png);
  height: 100%;
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 1;
}
.troo_video_section:before {
  content: "";
  background-image: url(../../Images/videoSectionBG.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.troo_video_section:after {
  content: "";
  background-color: #191825;
  opacity: 0.9;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.troo_video_container {
  max-width: 1360px;
  margin: 0 auto;
  padding: 92px 30px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}
.video_container_left {
  width: 100%;
}
.videoHeader,
.viderPara {
  color: #ffff;
}
.viderPara {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
}
.videoHeader {
  margin-bottom: 15px;
}

.video_container_right {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* play btn */
.play_btn {
  text-decoration: none;
  background-image: linear-gradient(
    to right,
    #6400a4,
    #5e07b5,
    #5411c6,
    #411bd8,
    #1225eb
  );
  border: 2px dotted white;
  color: aliceblue;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.play_btn::after {
  position: absolute;
  content: "";
  bottom: -7px;
  top: -7px;
  left: -7px;
  right: -7px;
  background-color: transparent;
  border-radius: 50%;
  border: 4px solid #ffffff50;
  margin: 0px;
  opacity: 1;
  transform-origin: center;
  animation: anim 900ms linear infinite;
}

.play_btn::before {
  position: absolute;
  content: "";
  bottom: -20px;
  top: -20px;
  left: -20px;
  right: -20px;
  background-color: transparent;
  border-radius: 50%;
  border: 4px solid #ffffff50;
  margin: 0px;
  opacity: 0.8;
  transform-origin: center;
  animation: anim 800ms linear 500ms infinite;
}

@keyframes anim {
  from {
    margin: 0px;
    opacity: 1;
  }
  to {
    margin: -15px;
    opacity: 0;
  }
}

@media only screen and (max-width: 1300px) {
  .troo_video_container {
    padding: 10px 30px;
  }
  .video_container_left {
    width: 100%;
    text-align: center;
  }

}
@media only screen and (max-width: 1180px) {
 
}
@media only screen and (max-width: 768px) {
  .troo_video_container {
    display: block;
  }
  .troo_video_section:before {
    display: none;
  }
  .video_container_right {
    display: none;
  }
}
@media only screen and (max-width: 415px) {
  .troo_video_container {
    padding: 20px 20px;
  }
}
